import React from "react";
import { Image, tokens, makeStyles, Tooltip, InfoLabel, MessageBar } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

export interface RedirectInfoProps {
    message : string
    error: {
        message: string
    }
 }

const useStyles = makeStyles({
    titleHeader: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        paddingTop: "80px",
        backgroundColor: tokens.colorNeutralBackground3,
    },
    message: {
        fontSize: tokens.fontSizeHero800,
        fontWeight: tokens.fontWeightRegular,
        fontColor: tokens.colorNeutralBackgroundStatic,
    },
});

const RedirectInfo: React.FC<RedirectInfoProps> = (props: RedirectInfoProps) => {
    const styles = useStyles();
    const [t] = useTranslation('common');
    const errorHidden = props.error.message === "" || props.error.message === undefined || props.error.message === null;

    return (
        <section className={styles.titleHeader}>
            <Image height="40" src="assets/logo-techstack.png" />
            <h1 className={styles.message}>{props.message}</h1>
            <div hidden={errorHidden}>
                <MessageBar intent="error"> 
                    {props.error.message}
                </MessageBar>
            </div>
        </section>
    );
};

export default RedirectInfo;
