import * as React from "react";
import {
    Combobox,
    Option,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { Language } from "../../types/language";

export interface LanguageComboboxProps {
    selectedLanguage: Language;
    onChanged: (language: Language) => void;
}

export const LanguageCombobox = (props: LanguageComboboxProps) => {
    const { t } = useTranslation("common");

    return (
        <Combobox
            placeholder={t('languageCombobox.placeholder')}
            defaultSelectedOptions={props.selectedLanguage ? [props.selectedLanguage] : [Language.AutoDetect]}
            onOptionSelect={(_, data) => props.onChanged(data.optionValue as Language)}
            defaultValue={t(`language.${props.selectedLanguage ? props.selectedLanguage : Language.AutoDetect}`)}
        >
            {
                Object.values(Language).map((key) => (
                    <Option key={key} value={key}>
                        {t(`language.${key}`)}
                    </Option>
                ))
            }
        </Combobox>

    );
};

export default LanguageCombobox;