import { t } from "i18next";

declare var ADDIN_MANIFEST_XML_VERSION: string;

export function getExcelAddinWebAppVersion(): string
{
    if (ADDIN_MANIFEST_XML_VERSION){
        return `${ADDIN_MANIFEST_XML_VERSION}`;
    }
    return t('version.excelAddinWebAppUnknownVersionMessage.unknownVersion');
}