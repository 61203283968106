import React, { Fragment } from "react";
import { makeStyles } from "@fluentui/react-components";
import SettingsView from "../views/TaskPane/Settings/SettingsView";
import ConnectView from "../views/TaskPane/Connect/ConnectView";
import { Route, Routes } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import { SelectedSystemProvider, useSelectedSystem } from "../providers/SelectedSystemProvider";
import LoginView from "../views/Authentication/Login/LoginView";
import LoginCallbackView from "../views/Authentication/Login/LoginCallbackView";
import LogoutView from "../views/Authentication/Logout/LogoutView";
import LogoutCallbackView from "../views/Authentication/Logout/LogoutCallbackView";
import { AddInProvider } from "../providers/AddInProvider";
import { SettingsProvider } from "../providers/SettingsProvider";
import { NotificationProvider } from "../providers/NotificationProvider";
import 'react-toastify/dist/ReactToastify.min.css'

interface AppProps {
}

const useStyles = makeStyles({
    root: {
        minHeight: "100vh",
    },
});

const App = (_: AppProps) => {
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <Router>
                <Routes>
                    <Route path="/" element={
                        <SettingsProvider storeSettings={true}>
                            <NotificationProvider>
                                <SelectedSystemProvider>
                                    <AddInProvider>
                                        <ConnectView />
                                    </AddInProvider>
                                </SelectedSystemProvider>
                            </NotificationProvider>
                        </SettingsProvider>}
                    />
                    <Route path="/settings" element={
                        <SettingsProvider storeSettings={true}>
                            <NotificationProvider>
                                <SelectedSystemProvider>
                                    <AddInProvider>
                                        <SettingsView />
                                    </AddInProvider>
                                </SelectedSystemProvider>
                            </NotificationProvider>
                        </SettingsProvider>}
                    />
                    <Route path="/auth">
                        <Route path="login/:activeSystem/:isoLanguageCode" element={ <LoginView /> } />
                        <Route path="login-callback" element={<LoginCallbackView />} />
                        <Route path="logout/:activeSystem/:isoLanguageCode" element={<LogoutView />} />
                        <Route path="logout-callback" element={<LogoutCallbackView />} />
                    </Route>
                </Routes>
            </Router>
        </div>
    );
};

export default App;
