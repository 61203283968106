import { InMemoryWebStorage, SigninRedirectArgs, SignoutRedirectArgs, User, UserManager, WebStorageStateStore } from "oidc-client-ts";
import { AddInSystemConfigurationInfo } from "../../types/addin-system-configuration-info";
import { getPostLogoutRedirectUrl, getRedirectUrl } from "./authentication-helper";

export enum AuthenticationClientState {
    Uninitialized,
    Initialized
}

export enum AuthenticationStoreType {
    LocalStorage,
    ImMemory
}

export class AuthenticationClient {
    private _userManager: UserManager | null = null;
    private _state: AuthenticationClientState = AuthenticationClientState.Uninitialized;
    private _storeType: AuthenticationStoreType;

    constructor(storeType: AuthenticationStoreType) {
        this._storeType = storeType;
    }

    setConfiguration(configuration: AddInSystemConfigurationInfo | null) {

        var store = null;
        switch (this._storeType) {
            case AuthenticationStoreType.LocalStorage:
                store = new WebStorageStateStore({
                    prefix: "auth",
                    store: window.localStorage
                });
                break;
            case AuthenticationStoreType.ImMemory:
                store = new WebStorageStateStore({
                    prefix: "auth",
                    store: new InMemoryWebStorage()
                });
                break;
            default:
                throw new Error("Unknown store type.");
        }

        this._userManager?.stopSilentRenew();
        this._userManager = new UserManager({
            authority: configuration?.oAuthUrl || "",
            client_id: configuration?.clientId || "",
            scope: configuration?.scope || "",
            response_type: "code",
            redirect_uri: getRedirectUrl(),
            post_logout_redirect_uri: getPostLogoutRedirectUrl(),
            filterProtocolClaims: true,
            automaticSilentRenew: false,
            staleStateAgeInSeconds: 60 * 5,
            userStore: store,
            stateStore: store
        });

        this._state = configuration == null ? AuthenticationClientState.Uninitialized : AuthenticationClientState.Initialized;
    }

    getUser(): Promise<User | null> {
        if (this._state === AuthenticationClientState.Uninitialized || this._userManager == null) {
            return Promise.reject("AuthenticationClient not initialized.");
        }

        return this._userManager.getUser();
    }

    async silentRenew(): Promise<User | null> {
        if (this._state === AuthenticationClientState.Uninitialized || this._userManager == null) {
            return Promise.reject("AuthenticationClient not initialized.");
        }

        return this._userManager.signinSilent();
    }

    async storeUser(user: User | null): Promise<void> {
        if (this._state === AuthenticationClientState.Uninitialized || this._userManager == null) {
            return Promise.reject("AuthenticationClient not initialized.");
        }

        this._userManager.stopSilentRenew();
        await this._userManager.storeUser(user);
        if (user) {
            this._userManager.startSilentRenew();
        }

        console.log(`storeUser:`, user);
    }

    signinRedirect(args?: SigninRedirectArgs): Promise<void> {
        if (this._state === AuthenticationClientState.Uninitialized || this._userManager == null) {
            return Promise.reject("AuthenticationClient not initialized.");
        }

        return this._userManager.signinRedirect(args);
    }

    signoutRedirect(args?: SignoutRedirectArgs): Promise<void> {
        if (this._state === AuthenticationClientState.Uninitialized || this._userManager == null) {
            return Promise.reject("AuthenticationClient not initialized.");
        }

        return this._userManager.signoutRedirect(args);
    }

    signinCallback(url?: string): Promise<User | void> {
        if (this._state === AuthenticationClientState.Uninitialized || this._userManager == null) {
            return Promise.reject("AuthenticationClient not initialized.");
        }

        return this._userManager.signinCallback(url);
    }

    signoutCallback(url = window.location.href, keepOpen = false): Promise<void> {
        if (this._state === AuthenticationClientState.Uninitialized || this._userManager == null) {
            return Promise.reject("AuthenticationClient not initialized.");
        }

        return this._userManager.signoutCallback(url, keepOpen);
    }

    clearStaleState(): Promise<void> {
        if (this._state === AuthenticationClientState.Uninitialized || this._userManager == null) {
            return Promise.reject("AuthenticationClient not initialized.");
        }

        return this._userManager.clearStaleState();
    }
}