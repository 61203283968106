import React, { useEffect, useId, useState } from "react";
import { 
    tokens,
    makeStyles, 
    Button, 
    Input, 
    Label, 
    shorthands,
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    Tooltip, } from "@fluentui/react-components";
import { DeleteRegular, EditRegular } from '@fluentui/react-icons';
import { AddInSystemInfo } from "../../types/addin-system-info";
import { AddInSystemClient } from "../../utils/addin-system-client";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { clone } from "lodash";
import SystemStatusBadge from "../SystemStatusBadge/SystemStatusBadge";
import { useNotification } from "../../providers/NotificationProvider";

export interface SystemSettingsTabProps {
    systems: AddInSystemInfo[];
    onSystemsChanged: (systems: AddInSystemInfo[]) => void;
  }

const useStyles = makeStyles({
    message: {
        fontSize: tokens.fontSizeBase500,
        fontColor: tokens.colorNeutralBackgroundStatic,
        fontWeight: tokens.fontWeightRegular,
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        width: "80%",
        paddingLeft: "10%",
        paddingRight: "10%",
    },
    systems: {
        display: "flex",
        flexDirection: "column",
    },
    buttons: {
        display: "flex",
        flexDirection: "row",
        ...shorthands.gap("2px"),
        justifyContent: "center",
    },
    panels: {
        ...shorthands.padding(0, "10px"),
        "& th": {
        textAlign: "left",
        ...shorthands.padding(0, "30px", 0, 0),
        },
    },
    divider: {
        minHeight: "50px",
    },
    tableButtons: {
        display: "block",
        textAlign: "right",
    },
});

const SystemSettingsTab = (props: SystemSettingsTabProps) => {
    const inputNameId = useId();
    const inputUrlId = useId();
    const styles = useStyles();
    const [t] = useTranslation("common");
  
    const [systems, setSystems] = useState<AddInSystemInfo[]>(props.systems);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [selectedSystemName, setSelectedSystemName] = useState<string>("");
    const {notifyError} = useNotification();

    useEffect(() => {
        props.onSystemsChanged(systems);
    }, [systems]);

    function getSystemByName(name: string): AddInSystemInfo | null {
        const clonedSystems = clone(systems);

        const foundSystem = clonedSystems.find(s => s.name === name);
        if (foundSystem) {
            return foundSystem;
        }
        
        return null
    }

    function addSystem(url: string, name: string): void {
        const systemsClone = clone(systems);
        
        if (systemsClone.some(system => system.url === url || system.name === name)){
            throw new Error(t('system.alreadyExistsMessage'));
        }

        setSystems([...systemsClone, { name: name, url: url }]);
    }

    function updateSystem(currentName: string, newUrl: string, newName: string): void {
        const systemsClone = clone(systems);

        const index = systemsClone.findIndex(s => s.name == currentName);
        if (index === -1) {
            throw new Error(t('system.notFoundMessage'));
        }

        const exists = systemsClone.some(system => system.name !== currentName && (system.url === newUrl || system.name === newName));
        if (exists) {
            throw new Error(t('system.alreadyExistsMessage'));
        }
         
        systemsClone[index] = { name: newName, url: newUrl };
        setSystems(systemsClone);
    }

    function removeSystemByName(name: string): void {
        const systemsClone = clone(systems);
        
        const index = systemsClone.findIndex(s => s.name == name);
        if (index === -1) {
            throw new Error(t('system.notFoundMessage'));
        }

        systemsClone.splice(index, 1);
        setSystems(systemsClone);
    }

    async function onAddClick() : Promise<void> {
        try {
            setIsBusy(true);
            const discoveredUrl = await AddInSystemClient.discoverConfigurationUrl(url);
            try {
                addSystem(discoveredUrl || url, name);
                setName("");
                setUrl("");
            } catch (e) {
                if (e instanceof Error) {
                    notifyError(e.message);
                } else {
                    notifyError("An error occurred while adding the system");
                }
            }
        } finally {
            setIsBusy(false);
        }
    }

    async function onCancelClick() {
        setSelectedSystemName("");
        setName("");
        setUrl("");
    }

    async function onUpdateClick() {
        try {
            setIsBusy(true);
            try {
                updateSystem(selectedSystemName, url, name);
                setSelectedSystemName("");
                setName("");
                setUrl("");
            } catch (e) {
                if (e instanceof Error) {
                    notifyError(e.message);
                } else {
                    notifyError("An error occurred while updating the system");
                }
            }
        } finally {
            setIsBusy(false);
        }
    }    

    async function onChangeName(name: string) {
        setName(name);
    }

    async function onChangeUrl(url: string) {
        setUrl(url);
    }

    async function onRemoveClick(name: string) {
        try {
            setIsBusy(true);
            try {
                removeSystemByName(name);
            } catch (e) {
                if (e instanceof Error) {
                    notifyError(e.message);
                } else {
                    notifyError("An error occurred while removing the system");
                }
            }
        } finally {
            setIsBusy(false);
        }
    }

    async function onEditClick(name: string) {
        const system = getSystemByName(name);
        setSelectedSystemName(name);
        setName(system?.name || "");
        setUrl(system?.url || "");
    }
    
    async function onSelectSystem(name: string, url: string) {
        setName(name);
        setUrl(url);
    }

    return (
        <div>
        <div className={styles.systems}>
            <Label htmlFor={inputNameId}>{t("systemSettings.namePropertyTitle")}</Label>
            <Input placeholder="Name" inputMode="text" id={inputNameId} value={name} onChange={(e) => onChangeName(e.target.value)} />

            <Label htmlFor={inputUrlId}>{t("systemSettings.urlPropertyTitle")}</Label>
            <Input placeholder="Url" inputMode="url" id={inputUrlId} value={url} onChange={(e) => onChangeUrl(e.target.value)} />

            <div className={styles.buttons}>
                {selectedSystemName === ""
                    ? 
                        <Button appearance="primary" disabled={isBusy} onClick={onAddClick}>{t("add.button")}</Button>
                    : <>
                        <Button appearance="primary" disabled={isBusy} onClick={onUpdateClick}>{t("update.button")}</Button>
                        <Button appearance="primary" disabled={isBusy || selectedSystemName == ""} onClick={() => onCancelClick()}>{t("cancel.button")}</Button>
                    </>}
            </div>
        </div>

        <Table arial-label="Default table">
            <TableHeader>
                <TableRow>
                    <TableHeaderCell key="name">{t("systemSettings.namePropertyTitle")}</TableHeaderCell>
                    <TableHeaderCell key="url">{t("systemSettings.urlPropertyTitle")}</TableHeaderCell>
                    <TableHeaderCell key="operation"></TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {systems.map((system) => (
                    <TableRow key={system.url} onClick={() => onSelectSystem(system.name,system.url)}>
                        <TableCell>
                            <TableCellLayout
                            truncate={true}
                            media={
                                <SystemStatusBadge 
                                    systemName={system.name} 
                                    systemUrl={system.url} />
                            }
                            >
                            <Tooltip content={system.name} relationship="description">
                                <span>{system.name}</span>
                            </Tooltip>
                            </TableCellLayout>
                        </TableCell>
                        <TableCell>
                            <TableCellLayout truncate={true} >
                            <Tooltip content={system.url} relationship="description">
                                <span>{system.url}</span>
                            </Tooltip>
                            </TableCellLayout>
                        </TableCell>
                        <TableCell>
                            <TableCellLayout className={styles.tableButtons} >
                            <Tooltip content={t("edit.tooltip")} relationship="description">
                                <Button disabled={selectedSystemName === system.name} icon={<EditRegular />} onClick={() => onEditClick(system.name)} aria-label="Edit" />
                            </Tooltip>
                            <Tooltip content={t("delete.tooltip")} relationship="description">
                                <Button disabled={selectedSystemName === system.name} icon={<DeleteRegular />} onClick={() => onRemoveClick(system.name)} aria-label="Delete" />
                            </Tooltip>
                            </TableCellLayout>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </div>
    );
}

export default SystemSettingsTab;