import { Language } from "./language";

export interface AddInSettingsInfo {
    automaticSigninAfterConnect: boolean;
    automaticSignoutBeforeDisconnect: boolean;
    signinDialogHeightInPercentage: number;
    signinDialogWidthInPercentage: number;

    signoutDialogHeightInPercentage: number;
    signoutDialogWidthInPercentage: number;

    language: Language;
}

export const AddInSettingsInfoEmpty: AddInSettingsInfo = {
    automaticSigninAfterConnect: true,
    automaticSignoutBeforeDisconnect: true,
    signinDialogHeightInPercentage: 75,
    signinDialogWidthInPercentage: 75,

    signoutDialogHeightInPercentage: 75,
    signoutDialogWidthInPercentage: 75,

    language: Language.AutoDetect,
}