import * as React from "react";
import {
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Spinner,
    SplitButton,
} from "@fluentui/react-components";
import type { MenuButtonProps } from "@fluentui/react-components";
import { PlugConnectedRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { AddInProviderState } from "../../providers/AddInProvider";

export interface ConnectButtonProps {
    state: AddInProviderState;
    disabled: boolean;
    busy: boolean;
    onConnect: () => void;
    onDisconnect: () => void;
    onSignin: () => void;
    onSignout: () => void;
}

export const ConnectButton = (props: ConnectButtonProps) => {
    const { t } = useTranslation("common");

    switch (props.state) {
        case AddInProviderState.Connected:
            return (
                <Menu positioning="below-end">
                    <MenuTrigger disableButtonEnhancement>
                        {(triggerProps: MenuButtonProps) => (
                            <SplitButton disabled={props.disabled || props.busy}
                                icon={props.busy ? <Spinner size="tiny" /> : <PlugConnectedRegular />}
                                appearance="primary"
                                menuButton={triggerProps}
                                primaryActionButton={{ onClick: props.onSignin }}
                            >
                                {t("signin.title")}
                            </SplitButton>
                        )}
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem onClick={props.onDisconnect}>{t("disconnect.title")}</MenuItem>
                            <MenuItem onClick={props.onSignin}>{t("signin.title")}</MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            )
        case AddInProviderState.SignedIn:
            return (
                <Menu positioning="below-end">
                    <MenuTrigger disableButtonEnhancement>
                        {(triggerProps: MenuButtonProps) => (
                            <SplitButton disabled={props.disabled || props.busy}
                                icon={props.busy ? <Spinner size="tiny" /> : <PlugConnectedRegular />}
                                appearance="primary"
                                menuButton={triggerProps}
                                primaryActionButton={{ onClick: props.onDisconnect }}
                            >
                                {t("disconnect.title")}
                            </SplitButton>
                        )}
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList>
                            <MenuItem onClick={props.onDisconnect}>{t("disconnect.title")}</MenuItem>
                            <MenuItem onClick={props.onSignout}>{t("signout.title")}</MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            )
        case AddInProviderState.SignedOut:
            return (
                <Menu positioning="below-end">
                    <MenuTrigger disableButtonEnhancement>
                        {(triggerProps: MenuButtonProps) => (
                            <SplitButton disabled={props.disabled || props.busy}
                                icon={props.busy ? <Spinner size="tiny" /> : <PlugConnectedRegular />}
                                appearance="primary"
                                menuButton={triggerProps}
                                primaryActionButton={{ onClick: props.onSignin }}
                            >
                                {t("signin.title")}
                            </SplitButton>
                        )}
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList>
                            <MenuItem onClick={props.onDisconnect}>{t("disconnect.title")}</MenuItem>
                            <MenuItem onClick={props.onSignin}>{t("signin.title")}</MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            )
        case AddInProviderState.Disconnected:
        case AddInProviderState.NotConnected:
            return (
                <Menu positioning="below-end">
                    <MenuTrigger disableButtonEnhancement>
                        {(triggerProps: MenuButtonProps) => (
                            <SplitButton disabled={props.disabled || props.busy}
                                icon={props.busy ? <Spinner size="tiny" /> : <PlugConnectedRegular />}
                                appearance="primary"
                                menuButton={triggerProps}
                                primaryActionButton={{ onClick: props.onConnect }}
                            >
                                {t("connect.title")}
                            </SplitButton>
                        )}
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList>
                            <MenuItem onClick={props.onConnect}>{t("connect.title")}</MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            )
        case AddInProviderState.SignedIn:
            return (
                <Menu positioning="below-end">
                    <MenuTrigger disableButtonEnhancement>
                        {(triggerProps: MenuButtonProps) => (
                            <SplitButton disabled={props.disabled || props.busy}
                                icon={props.busy ? <Spinner size="tiny" /> : <PlugConnectedRegular />}
                                appearance="primary"
                                menuButton={triggerProps}
                                primaryActionButton={{ onClick: props.onDisconnect }}
                            >
                                {t("disconnect.title")}
                            </SplitButton>
                        )}
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList>
                            <MenuItem onClick={props.onDisconnect}>{t("disconnect.title")}</MenuItem>
                            <MenuItem onClick={props.onSignout}>{t("signout.title")}</MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            )
        default:
            throw new Error("Invalid state");
    }
};

export default ConnectButton;