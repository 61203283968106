export interface AddInSystemConfigurationInfo {
    functionsJsonUrl: string;
    functionsJsUrl: string;
    clientId: string;
    scope: string;
    oAuthUrl: string;
    analystServiceUrl: string;
    analystServiceVersion: string;
}

export const AddInSystemConfigurationInfoEmpty: AddInSystemConfigurationInfo = {
    functionsJsonUrl: "",
    functionsJsUrl: "",
    clientId: "",
    scope: "",
    oAuthUrl: "",
    analystServiceUrl: "",
    analystServiceVersion: ""
}