import { Language } from "../../types/language";

export function convertLanguageToTwoLetterIso(language: Language){
    if (!language) {
        return Office.context.displayLanguage;
    }
    
    switch (language) {
        case Language.AutoDetect:
            return Office.context.displayLanguage;
        case Language.English:
            return "en";
        case Language.German:
            return "de";
        default:
            throw new Error("Language not supported");
    }
}