import { t } from "i18next";
import { AddInSystemConfigurationInfo } from "../types/addin-system-configuration-info";

export class AddInSystemClient {

    static async discoverConfigurationUrl(url: string): Promise<string> {
        const discoverUrls = [
            new URL(url),
            new URL("/graphql/", url),
            new URL("/analyst/graphql/", url)
        ];

        for (const discoverUrl of discoverUrls) {
            try {
                const config = await this.getConfiguration(discoverUrl.href);
                if (config) {
                    return discoverUrl.href;
                }
            } catch { }
        };

        return "";
    }
    
    static async checkAvailability(baseUrl: string): Promise<boolean> {
        try {
            const response = await fetch(baseUrl, {
                method: "HEAD",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            })

            return response.ok;
        } catch {
            return false;
        }
    }

    static async getConfiguration(baseUrl: string): Promise<AddInSystemConfigurationInfo | null> {
        try {
            const configuration = await this.getConfigurationV2(baseUrl);
            if (configuration){
                return configuration;
            }
        } catch { }

        try {
            const configuration = await this.getConfigurationV1(baseUrl);
            if (configuration){
                return configuration;
            }
        } catch { }

        return null;
    }

    static async getConfigurationV2(baseUrl: string): Promise<AddInSystemConfigurationInfo | null> {
        const query = `query configuration{
                    configuration{
                      clientId
                      scope
                      oAuthUrl
                      functionsJsonUrl
                      functionsJsUrl
                      analystServiceUrl
                      analystServiceVersion
                    }
                }`;

        const response = await fetch(baseUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({ query }),
        });
        const data = await response.json();

        const configuration = data?.data?.configuration as AddInSystemConfigurationInfo | null;
        if (!configuration) {
            return null;
        }
        return configuration
    }

    static async getConfigurationV1(baseUrl: string): Promise<AddInSystemConfigurationInfo | null> {
        const query = `query configuration{
                    configuration{
                      clientId
                      scope
                      oAuthUrl
                      functionsJsonUrl
                      functionsJsUrl
                      analystServiceUrl
                    }
                }`;

        const response = await fetch(baseUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({ query }),
        })
        const data = await response.json();

        const configuration = data?.data?.configuration as AddInSystemConfigurationInfo | null;
        if (!configuration) {
            return null;
        }

        configuration.analystServiceVersion = t('version.excelAddinVersionNotSupportedMessage');
        return configuration;
    }

    static async getFunctionsJson(functionsJsonUrl: string): Promise<string> {
        return await (await fetch(functionsJsonUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })).text();
    }

    static async getFunctionsJs(functionsJsUrl: string): Promise<string> {
        return await (await fetch(functionsJsUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })).text();
    }
}
