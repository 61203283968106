import React from "react";
import Header from "../../../components/Header/Header";
import SettingTabs from "../../../components/Settings/SettingTabs";
import { getExcelAddinWebAppVersion } from "../../../utils/helper/addin-manifest-version-helper";
import { useNavigate } from "react-router-dom";
import { useAddIn } from "../../../providers/AddInProvider";
import { useSettings } from "../../../providers/SettingsProvider";

interface SettingsViewProps {
}

const SettingsView = (_: SettingsViewProps) => {
    const navigate = useNavigate();
    const {systems, commonSettings, setSystems, setCommonSettings} = useSettings();
    const {activeConfiguration} = useAddIn();

    const onCloseClick = () => {
        navigate("/");
    };

    return (
        <div>
            <Header 
                excelAddinWebAppVersion={getExcelAddinWebAppVersion()}
                excelAddinVersion={activeConfiguration?.current?.analystServiceVersion || ""}
                />
        
            <SettingTabs
                onSaveSystemsClick={(systems) => setSystems(systems)}
                onSaveSettingsClick={(commonSettings) => setCommonSettings(commonSettings)}
                onCloseClick={onCloseClick}
                systems={systems}
                settings={commonSettings}
            />
        </div>
    );

};

export default SettingsView;
