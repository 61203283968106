import React, { useEffect, useState } from "react";
import { makeStyles, Switch, Divider, Label } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { AddInSettingsInfo } from "../../types/addin-settings-info";
import LanguageCombobox from "../LanguageCombobox/LanguageCombobox";

export interface CommonSettingsTabProps {
    settings: AddInSettingsInfo;
    onSettingsChanged: (settings: AddInSettingsInfo) => void;
}

const useStyles = makeStyles({
    divider: {
        minHeight: "10px",
    },
    selectLanguagePadding: {
        paddingRight: "1em"
    }
});

const CommonSettingsTab = (props: CommonSettingsTabProps) => {
    const styles = useStyles();
    const [settings, setSettings] = useState<AddInSettingsInfo>(props.settings);
    const [t] = useTranslation("common");

    useEffect(() => {
        props.onSettingsChanged(settings);
    }, [settings]);

    return (
        <div>
            <Switch
                onChange={(e) => setSettings({...settings, automaticSigninAfterConnect: e.target.checked})}
                checked={settings.automaticSigninAfterConnect}
                label={t('commonSettings.automaticSigninAfterConnectMessage')} />

            <Switch
                onChange={(e) => setSettings({...settings, automaticSignoutBeforeDisconnect: e.target.checked})}
                checked={settings.automaticSignoutBeforeDisconnect}
                label={t('commonSettings.automaticSignoutBeforeDisconnectMessage')} />

            <Divider className={styles.divider} />
            
            <Label className={styles.selectLanguagePadding}>{t('commonSettings.selectLanguage')}</Label>
            <LanguageCombobox
                selectedLanguage={settings.language}
                onChanged={(language) => setSettings({...settings, language: language})} />
        </div>
    );
};

export default CommonSettingsTab;