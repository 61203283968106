import React, { useEffect } from "react";
import { AuthenticationClient, AuthenticationStoreType } from "../../../utils/authentication/authentication-client";
import { AddInSystemClient } from "../../../utils/addin-system-client";
import { useTranslation } from "react-i18next";
import RedirectInfo from "../../../components/RedirectInfo/RedirectInfo";
import { makeStyles } from "@fluentui/react-components";
import { useParams } from "react-router-dom";
import { storeAuthenticationInfo } from "../../../utils/authentication/authentication-helper";
import i18next from "i18next";

interface LogoutViewProps {
}

const useStyles = makeStyles({
    root: {
        minHeight: "100vh",
    },
});

const LogoutView = (_: LogoutViewProps) => {
    
    const styles = useStyles();
    const [t] = useTranslation('common');
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const { activeSystem, isoLanguageCode } = useParams();
    
    if (!activeSystem){
        throw new Error("Active system is not defined");
    }

    if (!isoLanguageCode) {
        throw new Error("ISO language code is not defined");
    }
    
    useEffect(() => {
        const asyncUseEffekt = async () => {
            i18next.changeLanguage(isoLanguageCode);
            const configurationUrl = decodeURIComponent(activeSystem);
            const configuration = await AddInSystemClient.getConfiguration(configurationUrl);

            const authenticationClient = new AuthenticationClient(AuthenticationStoreType.LocalStorage);
            authenticationClient.setConfiguration(configuration);
            storeAuthenticationInfo({ activeSystemUrl: configurationUrl, isoLanguageCode: isoLanguageCode });
            
            try {
                await authenticationClient.signoutRedirect();
            } catch (ex) {
                setErrorMessage(t('authenticationRedirect.logout.redirectErrorMessage', { url: configuration?.oAuthUrl }));
                console.error(t('authenticationRedirect.logout.redirectErrorMessage', { url: configuration?.oAuthUrl }), ex);
            }
        }

        asyncUseEffekt();
    }, []);

    return (
        <div className={styles.root}>
            <RedirectInfo message={t('authenticationRedirect.login.message')} error={{ message: errorMessage }} />
        </div>
    );

};

export default LogoutView;
