import React, { ReactNode, createContext, useEffect, useState } from 'react';
// import toast from "react-hot-toast";
import { ToastContainer, toast } from 'react-toastify';

// Define the shape of your settings object
interface Notification {
    notifyError: (message: string) => void;
}

// Create a context for your settings
const NotificationContext = createContext<Notification | undefined>(undefined);

// Create a provider component
const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    function notifyError(message: string): void {
        toast.error(message);
    }

    const value: Notification = {
        notifyError: notifyError
    };

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
};

function useNotification(): Notification {
    const context = React.useContext(NotificationContext);
    if (context === undefined) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
}

export { NotificationContext, NotificationProvider, useNotification };
