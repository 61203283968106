import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { AddInSettingsInfo, AddInSettingsInfoEmpty } from '../types/addin-settings-info';
import { Language } from '../types/language';
import { AddInSystemInfo, AddInSystemInfoEmpty } from '../types/addin-system-info';
import i18next from 'i18next';
import { convertLanguageToTwoLetterIso } from '../utils/helper/language-converter';
import { pick } from 'lodash';

// Define the shape of your settings object
interface Settings {
    commonSettings: AddInSettingsInfo;
    setCommonSettings: (settings: AddInSettingsInfo) => void;
    systems: AddInSystemInfo[];
    setSystems: (systems: AddInSystemInfo[]) => void;
}

const defaultSettings: Settings = {
    commonSettings: {
        automaticSigninAfterConnect: true,
        automaticSignoutBeforeDisconnect: true,
        signinDialogHeightInPercentage: 75,
        signinDialogWidthInPercentage: 75,

        signoutDialogHeightInPercentage: 75,
        signoutDialogWidthInPercentage: 75,

        language: Language.AutoDetect,
    },
    setCommonSettings: () => {},
    systems: [],
    setSystems: () => {},
};

// Create a context for your settings
const SettingsContext = createContext<Settings | undefined>(undefined);

// Create a provider component
const SettingsProvider: React.FC<{ children: ReactNode, storeSettings: boolean }> = ({ children , storeSettings}) => {
    const [commonSettings, setCommonSettings] = useState<AddInSettingsInfo>(readCommonSettings());
    const [systems, setSystems] = useState<AddInSystemInfo[]>(readSystems());
    
    if (i18next.language !== convertLanguageToTwoLetterIso(commonSettings.language)) {
        i18next.changeLanguage(convertLanguageToTwoLetterIso(commonSettings.language));
    }
    
    function readCommonSettings(): AddInSettingsInfo {
        const rawValue = localStorage.getItem("addin-settings");
        if (!rawValue) {
            return defaultSettings.commonSettings;
        }

        const value = JSON.parse(rawValue) as AddInSettingsInfo;
        if (!value) {
            return defaultSettings.commonSettings;
        }

        // Ensure that the properties of the system are exactly the same as the AddInSettingsInfo type
        const valueWithExactProperties = pick(value, Object.keys(AddInSettingsInfoEmpty)) as AddInSettingsInfo;
        return valueWithExactProperties;
    }

    function readSystems(): AddInSystemInfo[] {
        const rawValue = localStorage.getItem("addin-systems");
        if (!rawValue) {
            return defaultSettings.systems;
        }

        const value = JSON.parse(rawValue) as AddInSystemInfo[];
        if (!value) {
            return defaultSettings.systems;
        }

        // Ensure that the properties of the system are exactly the same as the AddInSystemInfo type
        const valueWithExactProperties = value.map(item => pick(item, Object.keys(AddInSystemInfoEmpty)) as AddInSystemInfo);
        return valueWithExactProperties;
    }

    function store() {
        const commonSettingsValue = JSON.stringify(commonSettings);
        localStorage.setItem("addin-settings", commonSettingsValue);

        const systemsValue = JSON.stringify(systems);
        localStorage.setItem("addin-systems", systemsValue);
    }

    function load() {
        setCommonSettings(readCommonSettings());
        setSystems(readSystems());
    }

    // Load settings from local storage on component mount
    useEffect(() => {
        load();
    }, []);

    // Save settings to local storage whenever they change
    useEffect(() => {
        if (!storeSettings) {
            return;
        }
        
        store();
    }, [commonSettings, systems]);

    const value: Settings = {
        commonSettings: commonSettings,
        setCommonSettings: setCommonSettings,
        systems: systems,
        setSystems: setSystems,
    };

    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    );
};

function useSettings(): Settings {
    const context = React.useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }
    return context;
}

export { SettingsContext, SettingsProvider, useSettings };