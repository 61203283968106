import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import {I18nextProvider} from "react-i18next";
import { ToastContainer } from "react-toastify";
import i18next from "i18next";

import common_de from "./../locale/common.de.json";
import common_en from "./../locale/common.en.json";

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const rootElement: HTMLElement = document.getElementById("container")!;
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(() => {
    i18next.init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: "en",
        fallbackLng: "en",
        resources: {
            en: {
                common: common_en,
            },
            de: {
                common: common_de,
            },
        },
        debug: true,
    });

    root.render(
        <FluentProvider theme={webLightTheme}>
            <I18nextProvider i18n={i18next}>
                <ToastContainer position="top-center" stacked={true} closeButton={false} pauseOnFocusLoss={false} autoClose={3000} />
                <App />
            </I18nextProvider>
        </FluentProvider>
    );
});
    
if ((module as any).hot) {
    (module as any).hot.accept("./App", () => {
        const NextApp = require("./App").default;
        root.render(NextApp);
    });
}
