import * as React from "react";
import { Image, tokens, makeStyles, Tooltip, InfoLabel } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

export interface HeaderProps {
    excelAddinWebAppVersion: string;
    excelAddinVersion: string;
 }

const useStyles = makeStyles({
    welcome__header: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        paddingTop: "80px",
        backgroundColor: tokens.colorNeutralBackground3,
    },
    message: {
        fontSize: tokens.fontSizeHero800,
        fontWeight: tokens.fontWeightRegular,
        fontColor: tokens.colorNeutralBackgroundStatic,
    },
});

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
    const styles = useStyles();
    const [t] = useTranslation('common');

    return (
        <section className={styles.welcome__header}>
            <InfoLabel 
                info={
                    <>
                        {t('header.excelAddinWebAppVersionMessage', { version: props.excelAddinWebAppVersion })}<br/>
                        {t('header.excelAddinVersionMessage', { version: props.excelAddinVersion === "" ? "-" : props.excelAddinVersion })}
                    </>
                    }
                 size="large">
                <Image height="40" src="assets/logo-techstack.png" alt={t('header.title')} />
            </InfoLabel>
            <h1 className={styles.message}>{t('header.message')}</h1>
        </section>

    );
};

export default Header;
