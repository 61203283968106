import React, { useEffect, useId, useState } from "react";
import {
    Combobox,
    Option,
    OptionOnSelectData,
    SelectionEvents,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { AddInSystemInfo } from "../../types/addin-system-info";
import { AddInProviderState } from "../../providers/AddInProvider";
import SystemStatusBadge from "../SystemStatusBadge/SystemStatusBadge";

export interface SystemComboboxProps {
    showSystemStatus: boolean;
    state: AddInProviderState;
    systems: AddInSystemInfo[];
    selectedSystemUrl: string;
    onSelectedSystemUrl(systemUrl: string): void;
}

export const SystemCombobox = (props: SystemComboboxProps) => {
    const { t } = useTranslation("common");
    const comboBoxId = useId();

    const [quickConnectUrl, setQuickConnectUrl] = useState<string>("");
    const [currentInput, setCurrentInput] = useState<string>("");
    const [selectedSystem, setSelectedSystem] = useState<string>("");

    useEffect(() => {
        if (props.selectedSystemUrl == "") {
            return;
        }

        const selectedSystem = getSystemByUrl(props.selectedSystemUrl);
        if (selectedSystem) {
            setSelectedSystem(selectedSystem.url);
        }
        else {
            setQuickConnectUrl(props.selectedSystemUrl);
        }
    }, []);

    useEffect(() => {
        props.onSelectedSystemUrl(selectedSystem || quickConnectUrl);
    }, [quickConnectUrl, selectedSystem]);

    function isValidHttpUrl(str: string): boolean {
        let url;

        try {
          url = new URL(str);
        } catch (_) {
          return false;  
        }
        
        return url.protocol === "http:" || url.protocol === "https:";
    }

    function getSystemByUrl(url: string): AddInSystemInfo | null {
        const foundSystem = props.systems.find(s => s.url === url);
        if (foundSystem) {
            return foundSystem;
        }
        return null
    }

    function onOptionSelect(_: SelectionEvents, data: OptionOnSelectData) {
        setSelectedSystem(data.optionValue || "");
    }

    function onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setCurrentInput(ev.target.value);
        if (selectedSystem){
            setSelectedSystem("");
        }
        if (isValidHttpUrl(ev.target.value)) {
            setQuickConnectUrl(ev.target.value || "");
        }
        else{
            setQuickConnectUrl("");
        };
    }

    return (
        <Combobox
            aria-labelledby={comboBoxId}
            placeholder={t('connectView.selectSystemPlaceholderMessage')}
            onOptionSelect={onOptionSelect}
            selectedOptions={[selectedSystem]}
            onChange={onChange}
            value={getSystemByUrl(selectedSystem)?.name || selectedSystem || currentInput || quickConnectUrl}
            disabled={!(props.state === AddInProviderState.Disconnected || props.state === AddInProviderState.NotConnected)}
        >
            {props.systems.map((system) => (
                <Option key={system.url} value={system.url} text={system.name} >
                    {props.showSystemStatus && <SystemStatusBadge systemName={system.name} systemUrl={system.url} />}
                    {system.name}
                </Option>
            ))}
        </Combobox>
    );
};

export default SystemCombobox;