import React, { useState } from "react";
import { tokens, makeStyles, Button, Tooltip } from "@fluentui/react-components";
import { SettingsRegular } from "@fluentui/react-icons";
import { ConnectButton } from "../../../components/ConnectButton/ConnectButton";
import Header from "../../../components/Header/Header";
import { useTranslation } from "react-i18next";
import {getExcelAddinWebAppVersion} from "../../../utils/helper/addin-manifest-version-helper"
import { useNavigate } from "react-router-dom";
import { useSelectedSystem } from "../../../providers/SelectedSystemProvider";
import { AddInProviderState, useAddIn } from "../../../providers/AddInProvider";
import { AddInSystemInfo } from "../../../types/addin-system-info";
import { UserInfo } from "../../../types/user-info";
import { User } from "oidc-client-ts";
import { getLoginUrl, getLogoutUrl } from "../../../utils/authentication/authentication-helper";
import { useSettings } from "../../../providers/SettingsProvider";
import { SystemCombobox } from "../../../components/SystemCombobox/SystemCombobox";
import { useNotification } from "../../../providers/NotificationProvider";

export interface ConnectViewProps {
    
}

const useStyles = makeStyles({
    combobox: {
        display: "flex",
    },
    content: {
        width: "80vw",
        paddingLeft: "10vw",
        paddingRight: "10vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    message: {
        fontSize: tokens.fontSizeBase500,
        fontColor: tokens.colorNeutralBackgroundStatic,
        fontWeight: tokens.fontWeightRegular,
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    connectButton: {
        display: "flex",
        paddingTop: "5px",
    }
});

const ConnectView = (_: ConnectViewProps) => {
    const styles = useStyles();
    const [t] = useTranslation("common");
    const navigate = useNavigate();
    
    const {selectedSystem, setSelectedSystem} = useSelectedSystem();
    const {commonSettings, systems} = useSettings();
    const {notifyError} = useNotification();
    const {state, activeSystem, userInfo, activeConfiguration, signin, signout, connect, disconnect} = useAddIn();
    const [busy, setBusy] = useState<boolean>(false);

    //#region Helper Functions

    function showSigninDialog(signinCallback: (user: User | null, success: boolean) => Promise<void>): void {
        const url = getLoginUrl(activeConfiguration?.current?.analystServiceUrl || "", commonSettings.language);
        console.debug("Opening signin dialog with url: " + url);
    
        Office.context.ui.displayDialogAsync(
            url,
            {
                height: commonSettings.signinDialogHeightInPercentage,
                width: commonSettings.signinDialogWidthInPercentage,
                promptBeforeOpen: false
            },
            (asyncResult: Office.AsyncResult<Office.Dialog>) => {
                const dialog: Office.Dialog = asyncResult.value;
                dialog.addEventHandler(Office.EventType.DialogEventReceived, (args: {message: string, origin: string | undefined} | {error: number}) => {
                    if ("message" in args) {
                        console.debug("DialogEventReceived event was received from dialog. Message: " + args.message + " Origin: " + args.origin);
                    }
                    signinCallback(null, false);
                });
                dialog.addEventHandler(Office.EventType.DialogMessageReceived, (args: {message: string, origin: string | undefined} | {error: number}) => {
                    if ("message" in args){
                        console.debug("DialogMessageReceived event was received from dialog. Message: " + args.message + " Origin: " + args.origin);
                        const user = User.fromStorageString(args.message);
                        dialog.close();
                        signinCallback(user, true);
                    }
                    else {
                        console.error("DialogMessageReceived event was received from dialog. Error: " + args.error);
                        dialog.close();
                        signinCallback(null, false);
                    }
                });
            }
        );
    }

    function showSignoutDialog(signoutCallback: (success: boolean) => Promise<void>): void {
        const url = getLogoutUrl(activeConfiguration?.current?.analystServiceUrl || "", commonSettings.language);
        console.debug("Opening signout dialog with url: " + url);

        Office.context.ui.displayDialogAsync(
            url,
            {
                height: commonSettings.signoutDialogHeightInPercentage,
                width: commonSettings.signoutDialogWidthInPercentage,
                promptBeforeOpen: false
            },
            (asyncResult: Office.AsyncResult<Office.Dialog>) => {
                const dialog: Office.Dialog = asyncResult.value;
                dialog.addEventHandler(Office.EventType.DialogEventReceived, (args: {message: string, origin: string | undefined} | {error: number}) => {
                    console.debug("DialogEventReceived event was received from dialog.", args);
                    signoutCallback(false);
                });
                dialog.addEventHandler(Office.EventType.DialogMessageReceived, (args: {message: string, origin: string | undefined} | {error: number}) => {
                    console.debug("DialogMessageReceived event was received from dialog.", args);
                    dialog.close();
                    signoutCallback(true);
                });
            });
    }

    function buildMessage(state: AddInProviderState, activeSystem: AddInSystemInfo | undefined | null, userInfo: UserInfo | undefined | null): string {
        switch (state) {
            case AddInProviderState.SignedIn:
                return t("connectionState.signedIn.message", { system: activeSystem?.name, user: userInfo?.name });
            case AddInProviderState.Connected:
                return t("connectionState.connected.message", { system: activeSystem?.name });
            case AddInProviderState.SignedOut:
                return t("connectionState.signedOut.message");
            case AddInProviderState.NotConnected:
                return t("connectionState.notConnected.message");
            case AddInProviderState.Disconnected:
                return t("connectionState.disconnected.message");
            default:
                throw new Error("Unknown state");
        }
    }

    //#endregion

    //#region Event Handlers

    async function onDisconnectClick(): Promise<void> {
        if (commonSettings.automaticSignoutBeforeDisconnect) {
            setBusy(true);
            showSignoutDialog(async (_) => {
                try {
                    await signout();
                    await disconnect(true);
                } catch (e : any) {
                    const message = (e instanceof Error) ? e.message : e.toString();
                    notifyError(message);
                    console.error(message);
                    return;
                } finally {
                    setBusy(false);
                }
            });
        }
        else {
            setBusy(true);
            try {
                await disconnect(true);
            } catch (e : any) {
                const message = (e instanceof Error) ? e.message : e.toString();
                notifyError(message);
                console.error(message);
            } finally {
                setBusy(false);
            }
        }
    }

    async function onConnectClick() {
        if (commonSettings.automaticSigninAfterConnect) {
            setBusy(true);
            try {
                await connect(selectedSystem);
            } catch(e: any) {
                const message = (e instanceof Error) ? e.message : e.toString();
                console.error(message);
                notifyError(message);
                setBusy(false);
                return;
            }

            await showSigninDialog(async (user, success) => {
                if (success && user !== null) {
                    await signin(user);
                }
                setBusy(false);
            });
        } else {
            setBusy(true);
            try {
                await connect(selectedSystem);
            } catch(e: any) {
                const message = (e instanceof Error) ? e.message : e.toString();
                console.error(message);
                notifyError(message);
            } finally {
                setBusy(false);
            }
        }
    };

    async function onSigninClick() {
        setBusy(true);
        try {
            showSigninDialog(async (user, success) => {
                if (success && user !== null) {
                    await signin(user);
                }
                setBusy(false);
            });
        } finally {
            setBusy(false);
        }
    };

    async function onSignoutClick() {
        setBusy(true);
        try {
            showSignoutDialog(async () => {
                await signout();
                setBusy(false);
            });
        } finally {
            setBusy(false);
        }
    };

    const onSettingsClick = () => {
        navigate("/settings");
    }

    //#endregion

    return (
        <div>
            <Header 
                excelAddinWebAppVersion={getExcelAddinWebAppVersion()}
                excelAddinVersion={activeConfiguration?.current?.analystServiceVersion || ""}
                />
            <div className={styles.content}>
                <h2 className={styles.message}>{buildMessage(state,activeSystem?.current,userInfo?.current)}</h2>
                <div className={styles.combobox}>
                    <SystemCombobox
                        showSystemStatus={false}
                        systems={systems}
                        selectedSystemUrl={selectedSystem}
                        state={state}
                        onSelectedSystemUrl={setSelectedSystem}
                    />
                    <div>
                        <Tooltip content={t('settings.buttonTooltip')} relationship="label">
                            <Button appearance="primary" icon={<SettingsRegular />} onClick={onSettingsClick} />
                        </Tooltip>
                    </div>
                </div>
                <div className={styles.connectButton}>
                    <ConnectButton
                        busy={busy}
                        state={state}
                        disabled={!(selectedSystem != "")}
                        onConnect={onConnectClick}
                        onDisconnect={onDisconnectClick}
                        onSignin={onSigninClick}
                        onSignout={onSignoutClick} />
                </div>
            </div>
        </div>
    );
};

export default ConnectView;
