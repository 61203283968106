import { User } from "oidc-client-ts";
import { Subscription, Subject, timer } from 'rxjs';
import { isEqual, clone } from 'lodash';

export interface TokenChangedEvent {
    accessToken: string;
}

export class AuthenticationMonitor {
    private _tokenTimer: Subscription | null = null;
    private _intervalInMilliseconds: number;
    private _getUser: () => Promise<User | null>;

    private _tokenChanged: Subject<TokenChangedEvent> = new Subject<TokenChangedEvent>();
    private _lastTokenChangedEvent: TokenChangedEvent | null = null;

    constructor(getUser: () => Promise<User | null>, intervalInMilliseconds: number) {
        this._getUser = getUser;
        this._intervalInMilliseconds = intervalInMilliseconds;
    }

    get onTokenChanged(): Subject<TokenChangedEvent> {
        return this._tokenChanged;
    }

    start(): Promise<void> {
        if (this._tokenTimer) {
            return Promise.reject("AuthenticationMonitor already started.");
        }

        return this.check().finally(() => {
            this._tokenTimer = timer(0, this._intervalInMilliseconds).subscribe(() => {
                this.check();
            });
        });
    }

    private check(): Promise<void> {
        return this._getUser().then((user) => {
            const tokenChangedEvent: TokenChangedEvent = {
                accessToken: user?.access_token || ""
            };

            if (!isEqual(this._lastTokenChangedEvent, tokenChangedEvent)) {
                this._tokenChanged.next(tokenChangedEvent);
                this._lastTokenChangedEvent = clone(tokenChangedEvent);
            }
        }).catch(() => {
            const tokenChangedEvent: TokenChangedEvent = {
                accessToken: ""
            };

            if (!isEqual(this._lastTokenChangedEvent, tokenChangedEvent)) {
                this._tokenChanged.next(tokenChangedEvent);
                this._lastTokenChangedEvent = clone(tokenChangedEvent);
            }
        });
    }

    stop(): Promise<void> {
        return this.check().finally(() => {
            this._tokenTimer?.unsubscribe();
            this._lastTokenChangedEvent = null;
            this._tokenTimer = null;
        });
    }
}