import React, { useEffect } from "react";
import { makeStyles, Button, shorthands, TabList, Tab, TabValue, SelectTabEvent, SelectTabData, Divider } from "@fluentui/react-components";
import { AddInSystemInfo } from "../../types/addin-system-info";
import SystemSettingsTab from "./SystemSettingsTab";
import CommonSettingsTab from "./CommonSettingsTab";
import { AddInSettingsInfo } from "../../types/addin-settings-info";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

export interface SettingTabsProps {
    systems: AddInSystemInfo[];
    settings: AddInSettingsInfo;
    onSaveSystemsClick: (systems: AddInSystemInfo[]) => void;
    onSaveSettingsClick: (settings: AddInSettingsInfo) => void;
    onCloseClick: () => void;
}

const useStyles = makeStyles({
    content: {
        display: "flex",
        flexDirection: "column",
        width: "80%",
        paddingLeft: "10%",
        paddingRight: "10%",
    },
    buttons: {
        display: "flex",
        flexDirection: "row",
        ...shorthands.gap("2px"),
        justifyContent: "center",
    },
    panels: {
        ...shorthands.padding(0, "10px"),
        "& th": {
            textAlign: "left",
            ...shorthands.padding(0, "30px", 0, 0),
        },
    },
    divider: {
        minHeight: "50px",
    }
});

const SettingTabs = (props: SettingTabsProps) => {
    const styles = useStyles();
    const [t] = useTranslation("common");

    const tabKeys = {
        systems: "systems",
        common: "common",
    }

    const [selectetTab, setSelectedTab] = React.useState<TabValue>(tabKeys.systems);
    const [systems, setSystems] = React.useState<AddInSystemInfo[]>(props.systems);
    const [commonSettings, setCommonSettings] = React.useState<AddInSettingsInfo>(props.settings);
    const [hasChanges, setHasChanges] = React.useState<boolean>(false);
    const [initialSystems, setInitialSystems] = React.useState<AddInSystemInfo[]>(props.systems);
    const [initialCommonSettings, setInitialCommonSettings] = React.useState<AddInSettingsInfo>(props.settings);

    useEffect(() => {
        setInitialSystems(systems);
        setInitialCommonSettings(commonSettings);
    }, []);

    useEffect(() => {
        setHasChanges(getHasChanges());
    }, [systems, commonSettings, initialCommonSettings, initialSystems]);

    function getHasChanges() {
        return (!(isEqual(commonSettings, initialCommonSettings) && isEqual(systems, initialSystems)));
    }

    const onSaveAndCloseClick = () => {
        props.onSaveSystemsClick(systems);
        props.onSaveSettingsClick(commonSettings);
        props.onCloseClick();
    }

    const onCloseClick = () => {
        props.onCloseClick();
    }

    function onSystemSettingsChanged(changedSystems: AddInSystemInfo[]) {
        setSystems(changedSystems);
    }

    function onCommonSettingsChanged(changedSettings: AddInSettingsInfo) {
        setCommonSettings(changedSettings);
    }

    const onTabSelect = (_: SelectTabEvent, data: SelectTabData) => {
        setSelectedTab(data.value);
    };

    return (
        <div className={styles.content}>
            <TabList selectedValue={selectetTab} onTabSelect={onTabSelect}>
                <Tab value={tabKeys.systems}>{t("systemSettings.title")}</Tab>
                <Tab value={tabKeys.common}>{t("commonSettings.title")}</Tab>
            </TabList>

            <div className={styles.panels}>
                {selectetTab === tabKeys.systems && <SystemSettingsTab systems={systems} onSystemsChanged={onSystemSettingsChanged} />}
                {selectetTab === tabKeys.common && <CommonSettingsTab settings={commonSettings} onSettingsChanged={onCommonSettingsChanged} />}
            </div>

            <Divider className={styles.divider} />

            <div className={styles.buttons}>
                <Button
                    disabled={!hasChanges}
                    appearance="primary"
                    onClick={onSaveAndCloseClick}>{t("saveAndClose.title")}</Button>
                <Button
                    appearance="primary"
                    onClick={onCloseClick}>{t("close.title")}</Button>
            </div>
        </div>
    );
};

export default SettingTabs;