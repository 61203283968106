import { Language } from "../../types/language";
import { convertLanguageToTwoLetterIso } from "../helper/language-converter";
import { getLocationBaseUrl } from "../helper/window-location-helper";

export function getLoginUrl(activeSystemUrl: string, language: Language): string {
    return new URL(`taskpane.html#/auth/login/${encodeURIComponent(activeSystemUrl)}/${convertLanguageToTwoLetterIso(language)}`, getLocationBaseUrl()).toString()
}

export function getLogoutUrl(activeSystemUrl: string, language: Language): string {
    return new URL(`taskpane.html#/auth/logout/${encodeURIComponent(activeSystemUrl)}/${convertLanguageToTwoLetterIso(language)}`, getLocationBaseUrl()).toString()
}

export function getRedirectUrl(): string {
    return new URL("taskpane.html#/auth/login-callback", getLocationBaseUrl()).toString();
}

export function getPostLogoutRedirectUrl(): string {
    return new URL("taskpane.html#/auth/logout-callback", getLocationBaseUrl()).toString();
}

export interface AuthenticationInfo {
    activeSystemUrl: string;
    isoLanguageCode: string;
}

export function loadAuthenticationInfo() : AuthenticationInfo | null {
    const storeValue = localStorage.getItem("addin-authentication");
    if (!storeValue) {
        return null;
    }
    const value = JSON.parse(storeValue) as AuthenticationInfo;
    if (!value) {
        return null;
    }
    return value;
}

export function storeAuthenticationInfo(authenticationInfo: AuthenticationInfo | null): void  {
    if (!authenticationInfo) {
        localStorage.removeItem("addin-authentication");
        return;
    }
    const storeValue = JSON.stringify(authenticationInfo);
    localStorage.setItem("addin-authentication", storeValue);
} 