export class JavaScriptLoaderHelper {
    static loadJS(fileUrl: string, async = true) {
        let scriptEle = document.createElement("script");
        scriptEle.setAttribute("src", fileUrl);
        scriptEle.setAttribute("type", "text/javascript");
        scriptEle.setAttribute("async", async.toString());

        document.head.appendChild(scriptEle);

        // success event 
        scriptEle.addEventListener("load", () => {
            console.log("File loaded")
        });
        // error event
        scriptEle.addEventListener("error", (ev) => {
            console.log("Error on loading file", ev);
        });
    }

    static unloadJs(file: string) {
        let scriptEle = document.querySelector(`head > script[src$="${file}"]`);
        if (scriptEle != null)
            scriptEle.remove();
    }

    static replaceJs(removeFileName: string, loadFileUrl: string) {
        this.unloadJs(removeFileName);
        this.loadJS(loadFileUrl);
    }
}