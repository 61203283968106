import React, { useDeferredValue, useEffect, useState } from "react";
import { Avatar, PresenceBadgeStatus } from "@fluentui/react-components";
import { AddInSystemClient } from "../../utils/addin-system-client";

export interface SystemStatusBadgeProps {
    systemName: string;
    systemUrl: string;
}

const SystemStatusBadge: React.FC<SystemStatusBadgeProps> = (props: SystemStatusBadgeProps) => {
    const [state, setState] = useState<PresenceBadgeStatus>("unknown");
    const deferredState = useDeferredValue(state);

    async function getBadgeStatus(url: string): Promise<PresenceBadgeStatus> {
        try {
            if (await AddInSystemClient.checkAvailability(url)) {
                return "available";
            }
        } catch (_) { }

        return "offline";
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await getBadgeStatus(props.systemUrl);
            setState(data);
        };
        fetchData();
    }, []);

    return (
        <>
            {deferredState === "unknown"
                ? <Avatar
                    aria-label={props.systemName}
                    name={props.systemName}
                />
                : <Avatar
                    aria-label={props.systemName}
                    name={props.systemName}
                    badge={{ status: deferredState }}
                />}
        </>
    );
};

export default SystemStatusBadge;
