import React, { ReactNode, createContext, useEffect, useState } from 'react';

// Define the shape of your settings object
interface SelectedSystem {
    selectedSystem: string;
    setSelectedSystem: (selectedSystem: string) => void;
}

// Create a context for your settings
const SelectedSystemContext = createContext<SelectedSystem | undefined>(undefined);

// Create a provider component
const SelectedSystemProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [selectedSystem, setSelectedSystem] = useState<string>(read());

    function read(): string {
        const storeValue = localStorage.getItem("addin-selected-system");
        if (!storeValue) {
            return "";
        }
        return storeValue;
    }

    function load(): void {
        setSelectedSystem(read());
    }

    function store(): void {
        const rawValue = selectedSystem;
        localStorage.setItem("addin-selected-system", rawValue);
    }

    // Load settings from local storage on component mount
    useEffect(() => {
        load();
    }, []);

    // Save settings to local storage whenever they change
    useEffect(() => {
        store();
    }, [selectedSystem]);

    const value: SelectedSystem = {
        selectedSystem: selectedSystem,
        setSelectedSystem: setSelectedSystem
    };

    return (
        <SelectedSystemContext.Provider value={value}>
            {children}
        </SelectedSystemContext.Provider>
    );
};

function useSelectedSystem(): SelectedSystem {
    const context = React.useContext(SelectedSystemContext);
    if (context === undefined) {
        throw new Error('useSelectedSystem must be used within a SelectedSystemProvider');
    }
    return context;
}

export { SelectedSystemContext, SelectedSystemProvider, SelectedSystem, useSelectedSystem};
