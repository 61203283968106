import { User } from "oidc-client-ts";

export interface UserInfo{
    name: string;
    email: string;
}

export const UserInfoEmpty: UserInfo = {
    name: "",
    email: ""
}

// function createUserInfo(this: User): UserInfo {
//     return {
//         name: (this.profile.name || this.profile.preferred_username || this.profile.nickname) || "",
//         email: this.profile.email || "" 
//     };
// }

// declare module "oidc-client-ts"{
//     interface User {
//         createUserInfo(): UserInfo;
//     }
// }

// User.prototype.createUserInfo = createUserInfo;

export function createUserInfoFromUser(user: User): UserInfo {
    return {
        name: (user.profile.name || user.profile.preferred_username || user.profile.nickname) || "",
        email: user.profile.email || "" 
    };
}