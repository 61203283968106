import React, { MutableRefObject, ReactNode, createContext, useEffect, useRef, useState } from 'react';
import { AddInSystemConfigurationInfo, AddInSystemConfigurationInfoEmpty } from '../types/addin-system-configuration-info';
import { AddInSystemInfo, AddInSystemInfoEmpty } from '../types/addin-system-info';
import { UserInfo, UserInfoEmpty, createUserInfoFromUser } from '../types/user-info';
import { AuthenticationClient, AuthenticationStoreType } from '../utils/authentication/authentication-client';
import { AuthenticationMonitor } from '../utils/authentication/authentication-monitor';
import { User } from 'oidc-client-ts';
import { AddInSystemClient } from '../utils/addin-system-client';
import { JavaScriptLoaderHelper } from '../utils/helper/javascript-loader-helper';
import { useSettings } from './SettingsProvider';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';

enum AddInProviderState {
    NotConnected,
    Disconnected,
    Connected,
    SignedIn,
    SignedOut
}

interface AddInProvider {
    userInfo: null | MutableRefObject<UserInfo | null>;
    state: AddInProviderState;
    activeSystem: null |MutableRefObject<AddInSystemInfo>;
    activeConfiguration: null |MutableRefObject<AddInSystemConfigurationInfo>;
    signin: (user: User) => Promise<void>;
    signout: () => Promise<void>;
    connect: (addInSystemConfigurationUrl: string) => Promise<void>;
    disconnect: (unloadOfficeFunctions: boolean) => Promise<void>;
}

const defaultAddInProvider: AddInProvider = {
    userInfo: null,
    state: AddInProviderState.NotConnected,
    activeSystem: null,
    activeConfiguration: null,
    signin: async (_: User) => { },
    signout: async () => { },
    connect: async (_: string) => { },
    disconnect: async (_: boolean) => { }
};

const AddInProviderContext = createContext<AddInProvider | undefined>(undefined);

// Create a provider component
const AddInProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // State...
    const [state, setState] = useState<AddInProviderState>(defaultAddInProvider.state);
    const userInfo = useRef<UserInfo | null>(UserInfoEmpty);
    const activeSystem = useRef<AddInSystemInfo>(AddInSystemInfoEmpty);
    const activeConfiguration = useRef<AddInSystemConfigurationInfo>(AddInSystemConfigurationInfoEmpty);
    const [accessToken, setAccessToken] = useState<string>("");
    const {systems} = useSettings();
    const [t] = useTranslation("common");

    // Data...
    const _authenticationClient:  MutableRefObject<AuthenticationClient> = useRef<AuthenticationClient>(new AuthenticationClient(AuthenticationStoreType.ImMemory));
    const _authenticationMonitor: MutableRefObject<AuthenticationMonitor> = useRef<AuthenticationMonitor>(new AuthenticationMonitor(() => _authenticationClient.current.getUser(), 5000));

    useEffect(() => {
        var tokenSubscription = _authenticationMonitor.current.onTokenChanged.subscribe((event) => {
            setAccessToken(event.accessToken);
            console.debug(`token changed: ${event.accessToken}`);
        });
        return () => {
            tokenSubscription.unsubscribe();
        }
    });

    useEffect(() => {
        setAddInInterfaceValues();
    }, [state, accessToken]);

    function setAddInInterfaceValues() {
        window.ADDIN_USERNAME = userInfo.current?.name || "";
        window.ADDIN_ACCESS_TOKEN = accessToken;
        window.ADDIN_ANALYST_SERVICE_URL = activeConfiguration.current?.analystServiceUrl || "";
    }

    async function signin(user: User): Promise<void> {
        if (state === AddInProviderState.SignedIn) {
            console.debug('do not signin. already signed in.');
            return;
        }
        
        if (!user) {
            throw new Error("user is null");
        }

        await _authenticationClient.current.storeUser(user);
        await _authenticationMonitor.current.start();

        let userInfoFromUser = createUserInfoFromUser(user);
        userInfo.current = userInfoFromUser;

        console.debug(`signed in as ${userInfoFromUser.name} (${userInfoFromUser.email})`)
        console.debug(`loading office functions for active configuration`, activeConfiguration.current);
        await loadOfficeFunctions(activeConfiguration.current);

        setState(AddInProviderState.SignedIn);
    }

    async function signout(): Promise<void> {
        if (state === AddInProviderState.SignedOut) {
            console.debug('do not signout. already signed out.');
            return;
        }

        await _authenticationClient.current.storeUser(null);
        await _authenticationMonitor.current.stop();

        userInfo.current = UserInfoEmpty;
        setState(AddInProviderState.SignedOut);
    }

    function getDomainName(uri: string): string {
        try {
            const url = new URL(uri);
            return url.hostname;
        } catch (error) {
            return uri;
        }
    }

    function getSystemByUrl(url: string): AddInSystemInfo | null {
        const foundSystem = systems.find(s => s.url === url);
        if (foundSystem) {
            return foundSystem;
        }
        return null
    }

    async function connect(addInSystemConfigurationUrl: string): Promise<void> {
        if (state === AddInProviderState.Connected) {
            console.debug('do not connect. already connected.');
            return;
        }

        let system = getSystemByUrl(addInSystemConfigurationUrl);
        if (!system) {
            console.debug(`system not found for url: ${addInSystemConfigurationUrl}. Creating a new system.`);
            system = { name: getDomainName(addInSystemConfigurationUrl), url: addInSystemConfigurationUrl };
        }

        const configuration = await AddInSystemClient.getConfiguration(addInSystemConfigurationUrl);
        if (!configuration || configuration == null) {
            throw new Error(t("system.couldNotConnectMessage"));
        }
        
        await connectInternal(system, configuration);
    }

    async function connectInternal(system: AddInSystemInfo, configuration: AddInSystemConfigurationInfo): Promise<void> {
        console.debug(`connectInternal to system: ${system.url}, configuration: ${configuration}`);

        const functionsJson = await AddInSystemClient.getFunctionsJson(configuration.functionsJsonUrl);
        if (functionsJson == null)
            return;

        const functionsJs = await AddInSystemClient.getFunctionsJs(configuration.functionsJsUrl);
        if (functionsJs == null)
            return;

        console.debug(`loading office functions for active configuration`, configuration);
        _authenticationClient.current.setConfiguration(configuration);
        
        activeSystem.current = system;
        activeConfiguration.current = configuration;
        setState(AddInProviderState.Connected);
    }

    async function loadOfficeFunctions(activeConfiguration : AddInSystemConfigurationInfo): Promise<void> {
        console.debug(`loading office functions. functionsJson: ${activeConfiguration.functionsJsonUrl}, functionsJs: ${activeConfiguration.functionsJsUrl}`);
        const functionsJson = await AddInSystemClient.getFunctionsJson(activeConfiguration.functionsJsonUrl);
        if (functionsJson == null)
            return;

        const functionsJs = await AddInSystemClient.getFunctionsJs(activeConfiguration.functionsJsUrl);
        if (functionsJs == null)
            return;
        
        try {
            console.debug(`unloading functions.js`);
            JavaScriptLoaderHelper.unloadJs("functions.js");
        } catch (error) {
            console.error("error unloading functions.js", error);
        }

        try {
            console.debug(`loading functions json from ${functionsJson}`)
            await (Excel as any).CustomFunctionManager.register(functionsJson, '');
        } catch (error) {
            console.error("error loading functions.json", error, functionsJson);
        }
        
        try {
            console.debug(`loading functions.js from ${activeConfiguration.functionsJsUrl}`);
            JavaScriptLoaderHelper.loadJS(activeConfiguration.functionsJsUrl, false);
        } catch (error) {
            console.error("error loading functions.js", error, activeConfiguration.functionsJsUrl);
        }   
    }

    async function disconnect(unloadOfficeFunctions: boolean = true): Promise<void> {
        if (state === AddInProviderState.Disconnected || state === AddInProviderState.NotConnected) {
            console.debug('do not disconnect. already disconnected.');
            return;
        }

        await signout();

        if (unloadOfficeFunctions) {
            await (Excel as any).CustomFunctionManager.register(JSON.stringify(
                {
                    "functions": []
                }
            ), '');
            JavaScriptLoaderHelper.unloadJs("functions.js");
        }

        _authenticationClient.current.setConfiguration(null);

        activeSystem.current = AddInSystemInfoEmpty;
        activeConfiguration.current = AddInSystemConfigurationInfoEmpty;
        setState(AddInProviderState.Disconnected);
    }

    const value: AddInProvider = {
        userInfo: userInfo,
        state: state,
        activeSystem: activeSystem,
        activeConfiguration: activeConfiguration,
        signin: signin,
        signout: signout,
        connect: connect,
        disconnect: disconnect
    }

    return (
        <AddInProviderContext.Provider value={value}>
            {children}
        </AddInProviderContext.Provider>
    );
};

function useAddIn(): AddInProvider {
    const context = React.useContext(AddInProviderContext);
    if (context === undefined) {
        throw new Error('useAddIn must be used within a AddInProviderContext');
    }
    return context;
}

export { AddInProvider, useAddIn, AddInProviderState };
