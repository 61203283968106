import React, { useEffect } from "react";
import { AuthenticationClient, AuthenticationStoreType } from "../../../utils/authentication/authentication-client";
import { AddInSystemClient } from "../../../utils/addin-system-client";
import RedirectInfo from "../../../components/RedirectInfo/RedirectInfo";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@fluentui/react-components";
import { loadAuthenticationInfo, storeAuthenticationInfo } from "../../../utils/authentication/authentication-helper";
import i18next from "i18next";

interface LogoutCallbackViewProps {
}

const useStyles = makeStyles({
    root: {
        minHeight: "100vh",
    },
});

const LogoutCallbackView = (_: LogoutCallbackViewProps) => {
    const styles = useStyles();
    const [t] = useTranslation('common');

    useEffect(() => {
        const asyncUseEffekt = async () => {
            const authenticationInfo = loadAuthenticationInfo();
            if (!authenticationInfo) {
                throw new Error("Authentication info is not defined");
            }

            if (i18next.language !== authenticationInfo.isoLanguageCode){
                i18next.changeLanguage(authenticationInfo.isoLanguageCode);
            }

            const configurationUrl = authenticationInfo.activeSystemUrl;
            const configuration = await AddInSystemClient.getConfiguration(configurationUrl);

            const authenticationClient = new AuthenticationClient(AuthenticationStoreType.LocalStorage);
            authenticationClient.setConfiguration(configuration);

            await authenticationClient.storeUser(null);
            await authenticationClient.clearStaleState();

            storeAuthenticationInfo(null);
            Office.context.ui.messageParent('');
        }

        asyncUseEffekt();
    }, []);

    return (
        <div className={styles.root}>
            <RedirectInfo message={t('authenticationRedirect.logoutCallback.message')} error={{ message: "" }} />
        </div>
    );

};

export default LogoutCallbackView;
